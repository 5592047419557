export const FESAHeaderContainerDesktop = () => import("./fesa-header-container")
export { FESAMenuMobile } from "./fesa-menu-mobile"

export const FESAAccountStatsContainer = () => import("./fesa-account-stats-container")
export const FESAAccountCreditStats = () => import("./fesa-account-credit-stats")
export const FESAAccountBalanceStats = () => import("./fesa-account-balance-stats")
export const FESAAccountListPricesStats = () => import("./fesa-account-list-prices-stats")

export const FESACopyright = () => import("./fesa-copyright")
export const FESASimpleBanner = () => import("./fesa-simple-banner")
export const FESABanner = () => import("./fesa-banner")

export const FESACartHeader = () => import("./fesa-cart-header/")
export const FESAProfileHeader = () => import("./fesa-profile-header")

export const FESAProductCarousel = () => import("./fesa-product-carousel")

export const FESAMenuFooter = () => import("./fesa-menu-footer")
export const FESANavigationDesktop = () => import("./fesa-navigation-desktop")

export const FESAHelpButton = () => import("./fesa-help-button")

export const FESARecentlyBoughtContainer = () => import("./fesa-recently-bought-container")
export const FESARecentlyBought = () => import("./fesa-recently-bought")
export const FESANewsletter = () => import("./fesa-newsletter")

export const FESANavigationMobile = () => import("./fesa-navigation-mobile/component")

export const FESAProfileLinks = () => import("./fesa-profile-links")

export const FESAManualContent = () => import("./fesa-manual-content")
